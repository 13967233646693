import {useUserStore} from "~/stores/user";
import {TEACHER_ACCOUNT_REQUIRED_DISPLAY_REQUESTED} from "~/types/bus-event-names";

export const useIfTeacher = (f: Function) => {
  if (!useUserStore().isTeacher) {
    useNuxtApp().$mitt.emit(TEACHER_ACCOUNT_REQUIRED_DISPLAY_REQUESTED)
    return
  }

  f()
}
