<script setup lang="ts">
import {useDirectoriesStore} from "~/stores/directories"
import type {MediaCardData} from '~/types/media/MediaCardData'
import type {DirectoryData} from '~/types/DirectoryData'
import {useUserStore} from "~/stores/user";

export interface Props {
  mediaData: MediaCardData,
  context?: 'dialog'|'card'|null,
  output?: 'store'|'local'
}

const props = withDefaults(defineProps<Props>(), {
  output: 'store',
})

const directoriesStore = useDirectoriesStore()
const directoriesChecked = ref<DirectoryData[]>([])

const isMediaInCaptures = computed(() => {
  const userStore = useUserStore()
  // If the capture has just been created, check if the user is a teacher
  if (props.output === 'local') {
    return userStore.isTeacher
  }
  // Otherwise, check if the user's captures folder contains the media
  const captures = userStore.userData.captures
  return props.mediaData.mediaType === 'capture' ? (captures ? captures.filter(capture => capture.id === props.mediaData.id).length > 0 : false) : false;
})

const emit = defineEmits<{
  (e: 'update:directoriesChecked', value: DirectoryData[]): void,
  (e: 'resize'): void
}>()

function onCheckboxClick(directory: DirectoryData) {
  if (props.output === 'store') {
    directoriesStore.updateMediaPresenceInDirectory(directory, props.mediaData)
  } else {
    if (directoriesChecked.value.find(directoryChecked => directoryChecked.code === directory.code)) {
      directoriesChecked.value.splice(directoriesChecked.value.indexOf(directory), 1)
    } else {
      directoriesChecked.value.push(directory)
    }
    emit("update:directoriesChecked", directoriesChecked.value)
  }
}

function resetDirectories() {
  directoriesChecked.value = []
}

defineExpose({
  resetDirectories
})

function getCheckbox(directory: DirectoryData) {
  if (props.output === 'store') {
    return directoriesStore.isMediaInDirectory(directory, props.mediaData) ? 'check_box' : 'check_box_outline_blank'
  }
  return directoriesChecked.value.find(directoryChecked => directoryChecked.code === directory.code) ? 'check_box' : 'check_box_outline_blank'
}
</script>

<template>
  <div class="bg-white rounded-bento p-card">
    <p class="mb-2 font-bold" :class="context !== 'dialog' ? 'text-mini-heading' : 'text-button-small'">{{ $t('media.add_to') }}</p>
    <div class="flex text-button-small py-1 group cursor-default opacity-50" v-if="isMediaInCaptures">
      <span class="material-icons-outlined mr-2">check_box</span>
      <div class="pt-1">{{$t('capture.my_captures')}}</div>
    </div>
    <div class="flex text-button-small py-1 group cursor-default" v-for="dir in directoriesStore.directories">
      <div class="flex grow transition-colors hover:text-domain" @click="onCheckboxClick(dir)">
        <span class="material-icons-outlined mr-2">{{ getCheckbox(dir) }}</span>
        <span v-if="context !== 'card' && dir.isOpenToCaptures" class="material-icons-outlined mr-2 text-domain">graphic_eq</span>
        <div class="pt-1">{{ dir.name }}</div>
      </div>
      <NuxtLink :to="localePath({name: 'collection-code', params: { code: dir.code }})" class="material-icons-outlined mr-2 opacity-0 transition group-hover:opacity-100 group-focus-within:opacity-100 hover:text-domain">launch</NuxtLink>
    </div>
    <MoleculesMediaCardDirectoryCreator @resize="$emit('resize')" :media-data="mediaData" :output="output" />
  </div>
</template>
