<script setup lang="ts">
import TextInput from "~/components/atoms/form/TextInput.vue"
import {useDirectoriesStore} from "~/stores/directories"
import type {MediaCardData} from '~/types/media/MediaCardData'
import {FieldMaxLength} from '~/types/FieldMaxLength'

const opened = ref<boolean>(false)
const nameInput = ref<typeof TextInput|null>(null)
const name = ref<string>('')
const isPending = ref<boolean>(false)
const fetchError = ref<string|null>(null)

export interface Props {
  mediaData?: MediaCardData,
  output?: 'store'|'local'
}

const props = withDefaults(defineProps<Props>(), {
  output: 'store',
})

watch(opened, async (newValue, oldValue) => {
  emit('resize')
  if (newValue) {
    await nextTick()
    nameInput.value?.select()
  }
})

const emit = defineEmits<{
  (e: 'resize'): void
}>()

const disabled = computed(() => {
  return name.value === ''
})

async function createDirectory() {
  if (isPending.value) {
    return
  }

  isPending.value = true
  const {error: fetchError} = await useDirectoriesStore().createDirectory(name.value, props.output === 'store' ? props.mediaData : null)
  isPending.value = false

  if (fetchError) {
    return
  }

  opened.value = false
  name.value = ''
}
</script>

<template>
  <form @submit.prevent="createDirectory" :class="{'opacity-50 pointer-events-none': isPending}">
    <AtomsButton size="small" :label="$t('favorite_directory.create_directory')" before-icon="add" hover-scheme="none" class="w-full pl-0 pb-0 !justify-start" v-if="!opened" @click="opened = true" />
    <div v-else class="pt-4">
      <AtomsFormTextInput ref="nameInput"
                          v-model="name"
                          :error="fetchError"
                          :label="$t('favorite_directory.directory_name')"
                          :required="true"
                          :max-length="useFieldMaxLength(FieldMaxLength.FAVORITE_DIRECTORY_NAME)"
                          class="mb-2"/>
      <div class="flex justify-between">
        <AtomsButton size="small" :label="$t('_global.cancel')" @click="opened = false" hover-scheme="none" class="pl-0" />
        <AtomsButton size="small" :label="$t('_global.create')" dark-color="domain" tag="button" :disabled="disabled" />
      </div>
    </div>
  </form>
</template>